<template>
  <div>
    <Toolbar class="mb-4">
      <template #right> Sicht </template>
      <template #left>
        <Button
          icon="pi pi-times"
          class="p-button-danger"
          @click="$router.push('/admin/gifts')"
        />
      </template>
    </Toolbar>
    <div class="form-card row">
      <div class="col-md-12">
        <div class="mb-3">
          <label for="usersId" class="form-label"> Kunden </label>

          <Dropdown
            v-model.number="body.usersId"
            :options="usersList"
            optionLabel="xname"
            optionValue="id"
            :filter="true"
            placeholder="Select User"
            :showClear="true"
            class="w-100"
          >
            <template #value="slotProps">
              <div
                class="country-item country-item-value"
                v-if="slotProps.value"
              >
                <div>{{ getUser(slotProps.value) }}</div>
              </div>
              <span v-else>
                {{ slotProps.placeholder }}
              </span>
            </template>
            <template #option="slotProps">
              <div class="country-item">
                <div>{{ slotProps.option.xname }}</div>
              </div>
            </template>
          </Dropdown>
        </div>
      </div>
      <div class="col-md-2">
        <div class="mb-3">
          <label for="sexType" class="form-label">Typ</label>

          <b-form-select v-model="body.sexType" class="mb-3">
            <b-form-select-option value="MR">MR</b-form-select-option>
            <b-form-select-option value="MRS">MRS</b-form-select-option>
          </b-form-select>
        </div>
      </div>

      <div class="col-md-5">
        <div class="mb-3">
          <label for="fname" class="form-label">Vorname</label>
          <input
            type="text"
            class="form-control"
            id="fname"
            v-model="body.fname"
          />
        </div>
      </div>
      <div class="col-md-5">
        <div class="mb-3">
          <label for="lname" class="form-label">Last Name</label>
          <input
            type="text"
            class="form-control"
            id="lname"
            v-model="body.lname"
          />
        </div>
      </div>
      <div class="col-md-12">
        <div class="mb-3">
          <label for="price" class="form-label"> Preisplan </label>

          <input
            type="number"
            class="form-control"
            id="price"
            v-model.number="body.price"
          />
        </div>
      </div>
      <!-- <div class="col-md-6">
        <div class="mb-3">
          <label for="validationTooltip04">Pay Type</label>
          <select class="custom-select" id="validationTooltip04" v-model="body.baytype">
            <option :value="false">Cash</option>
            <option :value="true">Online</option>
          </select>
        </div>
      </div> -->
      <div class="col-md-10">
        <div class="mb-3">
          <label for="address" class="form-label"> AddressHome </label>
          <input
            type="text"
            class="form-control"
            id="address"
            v-model="body.address"
          />
        </div>
      </div>
      <div class="col-md-2">
        <div class="mb-3">
          <label for="zip" class="form-label"> PLZ </label>
          <input
            type="number"
            class="form-control"
            id="zip"
            v-model.number="body.zip"
          />
        </div>
      </div>

      <div class="col-md-6">
        <div class="mb-3">
          <label for="city" class="form-label"> Stadt </label>
          <input
            type="text"
            class="form-control"
            id="city"
            v-model="body.city"
          />
        </div>
      </div>
      <div class="col-md-6">
        <div class="mb-3">
          <label for="country" class="form-label">Land</label>
          <input
            type="text"
            class="form-control"
            id="country"
            v-model="body.country"
          />
        </div>
      </div>
      <div class="col-md-12">
        <div class="mb-3">
          <label for="phone" class="form-label"> Phone Number </label>
          <vue-tel-input
            v-model.trim="body.phone"
            v-bind="{
              mode: 'international',
            }"
            :allCountries="allCountries"
          ></vue-tel-input>
        </div>
      </div>
      <div class="col-md-12">
        <div class="mb-3">
          <label for="country" class="form-label"> Reciever Phone </label>
          <vue-tel-input
            v-model.trim="body.recieverphone"
            v-bind="{
              mode: 'international',
            }"
            :allCountries="allCountries"
          ></vue-tel-input>
        </div>
      </div>

      <div class="col-md-12">
        <div class="mb-3">
          <label for="validationTooltip04">Recieve Type</label>
          <select
            class="custom-select"
            id="validationTooltip04"
            v-model="body.recieverType"
          >
            <option value="email">email</option>
            <option value="himself">Receive by himself</option>
            <option value="post">post</option>
          </select>
        </div>
      </div>

      <div class="col-md-12" v-if="body.recieverType == 'email'">
        <div class="mb-3">
          <label for="email" class="form-label"> Email</label>
          <input
            type="email"
            class="form-control"
            id="email"
            v-model="body.email"
          />
        </div>
      </div>
      <div class="col-md-12 text-center">
        <Button
          label="Save"
          icon="pi pi-plus"
          class="ml-2 p-button-info"
          :disabled="disabledAdd"
          @click="add()"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    const d = new Date();
    const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
    const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
    const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
    return {
      allCountries: [
        ["Austria (Österreich)", "at", "43"],
        ["Germany (Deutschland)", "de", "49"],
        ["Switzerland (Schweiz)", "ch", "41"],
        ["Liechtenstein", "li", "423"],
      ].map(([name, iso2, dialCode]) => ({
        name,
        iso2: iso2.toUpperCase(),
        dialCode,
      })),

      minDateValue: new Date(`${ye}-${mo}-${da}`),
      body: {
        sexType: "MR",
        fname: null,
        lname: null,
        price: 0,
        address: null,
        zip: null,
        city: null,
        country: null,
        recieverType: "email",
        usersId: null,
        paymentMethod: "cach",
      },
      id: null,

      sectionList: [],
      optionsList: [
        { name: "No", value: 0 },
        { name: "Yes", value: 1 },
      ],

      isInvalid: false,
      disabledAdd: false,
      usersList: [],
    };
  },
  methods: {
    getUser(id) {
      if (id) {
        const user = this.usersList.find((el) => Number(el.id) == Number(id));
        return user.xname;
      } else {
        return "";
      }
    },
    add() {
      if (
        this.body.usersId &&
        this.body.fname &&
        this.body.lname &&
        this.body.price > 0 &&
        this.body.sexType
      ) {
        this.disabledAdd = true;
        this.$http.post(`gifts`, { ...this.body, admin: true }).then(
          (res) => {
            this.$toast.add({
              severity: "success",
              summary: "Done Successfully",
              detail: "Added Successfully",
              life: 3000,
            });
            this.disabledAdd = false;
            this.$router.push("/admin/gifts");
          },
          (err) => {
            this.disabledAdd = false;

            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: err.response.data.message,
              life: 3000,
            });
          }
        );
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please add the required fields",
          life: 3000,
        });
      }
    },
  },
  created() {
    this.$http.get(`users`).then((res) => {
      let usersList = res.data.data;
      for (let itm of usersList) {
        itm.xname =
          itm.id + " " + itm.fname + " " + itm.lname + " " + itm.phone;
      }
      this.usersList = usersList;
    });
  },
  watch: {
    "body.usersId"(val) {
      if (val) {
        const user = this.usersList.find((el) => el.id == val);

        this.body.usersId = user.id;
        this.body.email = user.email;
        this.body.phone = user.phone;
        this.body.sexType = user.sexType;
        this.body.fname = user.fname;
        this.body.lname = user.lname;
        this.body.address = user.address;
        this.body.zip = Number(user.zip);
        this.body.city = user.city;
        this.body.country = user.country;
      }
    },
  },
};
</script>
